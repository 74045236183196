<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="2">
            <v-btn
                class="btn-back elevation-0"
                @click="Back"
            >
              <v-icon dark left> mdi-arrow-left </v-icon>Back
            </v-btn>
          </v-col>
          <v-col>
            <div v-if="planCalendar.plan_calendar">
              ແຜນ {{planCalendar.plan_calendar.planName}} - {{planCalendar.plan_calendar.date}}
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <v-text-field
                outlined
                dense
                clearable
                label="ຄົ້ນຫາ"
                type="text"
                v-model="search"
            >
            </v-text-field>
          </v-col>
          <v-col class="d-flex">
            <v-autocomplete
                v-model="filterStatus"
                :items="statuses"
                label="ສະຖານະ"
                item-text="name"
                item-value="id"
                dense
                outlined
                clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="end">
          <v-col>
            <div>
              ລວມລູກຄ້າໝົດ <span class="info--text">{{planCalendar.total}}</span>
            </div>
          </v-col>
          <v-col>
            <div>
              ລໍຖ້າຈັດສົ່ງ <span class="info--text">{{planCalendar.pending}}</span>
            </div>
          </v-col>
<!--          <v-col>-->
<!--            <div>-->
<!--              ລວມຕຸກສົ່ງສຳເລັດລ່າສຸດ <span class="info&#45;&#45;text">{{planCalendar.sumTotalBottle}}</span>-->
<!--            </div>-->
<!--          </v-col>-->
          <v-col>
          <div>
            ສົ່ງສຳເລັດ <span class="success--text">{{planCalendar.success}} </span>
          </div>
        </v-col>
          <v-col>
            <div>
              ຍົກເລີກ <span class="warning--text">{{planCalendar.cancel}}</span>
            </div>
          </v-col>
          <v-col>
            <div>
              ປະຕິເສດ <span class="error--text">{{planCalendar.reject}}</span>
            </div>
          </v-col>
        </v-row>
        <br />
        <v-data-table
            :headers="planDetail"
            :items="routePlanDetail"
            :items-per-page="15"
            class="elevation-1"
            :loading="TableLoading"
            :disabled="TableLoading"
            :disable-pagination="true"
            hide-default-footer
            :search="search"
            loading-text="Loading... Please wait"
        >
          <template v-slot:item.status="{ item }">
            <v-chip :color="getBgColorFunc(item.status)" label>
              {{ statusLao(item.status)}}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="viewDetail(item.id)">
              mdi-eye
            </v-icon>
          </template>
          <template v-slot:item.route_plan_status="{ item }">
            <v-chip :color="getBgColorFunc(item.route_plan_status)" label>
              {{ statusLao(item.route_plan_status)}}
            </v-chip>
          </template>
          <template v-slot:item.invoice_date="{ item }">
            <div v-if="item.lastes_invoice">
              {{ item.lastes_invoice.invoice_date }}
            </div>
          </template>
          <!--Action -->
          <!--      <template v-slot:item.actions="{ item }">-->
          <!--        <v-icon small class="mr-2" @click="OpenModalEdit(item)">-->
          <!--          mdi-pencil-->
          <!--        </v-icon>-->
          <!--      </template>-->
        </v-data-table>
        <br>
        <Pagination
            v-if="pagination.last_page > 1"
            :pagination="pagination"
            :offset="offset"
            @paginate="fetchData()"
        ></Pagination>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// import manage from "@/mixins/routeplan/calendar";
import Pagination from "../../../plugins/pagination/pagination";
import queryOption from "../../../Helpers/queryOption";
import { getLaoStatus, getBgColor} from "../../../Helpers/Translate";
export default {
  // mixins: [manage],
  components: {
    Pagination,
  },
  data() {
    return {
      TableLoading:false,
      //Pagination
      offset: 15,
      pagination: {},
      per_page: 50,
      planCalendar:{},
      routePlanDetail: [],
      trucks: [],
      selectedTruck: '',
      search:'',
      filterStatus:'',
      statuses: [
        {
          id: 'pending',
          name: 'ລໍຖ້າຈັດສົ່ງ',
        },
        {
          id: 'success',
          name: 'ສົ່ງສຳເລັດ',
        },
        {
          id: 'cancel',
          name: 'ຍົກເລີກ',
        },
        {
          id: 'reject',
          name: 'ປະຕິເສດ',
        }
      ],
      planDetail: [
        {
          text: "ລຳດັດ",
          align: "center",
          sortable: false,
          value: "priority",
          width: "100px"
        },
        {
          text: "ລະຫັດລູກຄ້າ",
          align: "start",
          sortable: false,
          value: "customer_code",
          width: "140px"
        },      {
          text: "ລູກຄ້າ",
          align: "start",
          sortable: false,
          value: "customer_name",
          width: "160px"
        },      {
          text: "ເບີໂທ",
          align: "start",
          sortable: false,
          value: "phone",
        },
        // {text: "ວັນທີຊື້ລ່າສຸດ", value: "invoice_date",width: "140px"},
        {text: "ລວມຕຸກນ້ຳລ່າສຸດ", value: "bottle",width: "140px",  align: "center",},
        {text: "ສະຖານະຈັດສົ່ງ", value: "status", width: "150px"},
        // {text: "ສະຖານະແຜນເສັ້ນທາງ", value: "route_plan_status", width: "180px"},
        {
          text: "ບ້ານ",
          align: "start",
          sortable: false,
          value: "village_name",
          width: "120px"
        }, {
          text: "ເມືອງ",
          align: "start",
          sortable: false,
          value: "district_name",
          width: "140px"
        },
        {text: "ລາຍລະອຽດ", value: "description",width: "150px"},
        // {text: "ຜູ້ຮັບຜິດຊອບ", value: "created_by_name",width: "160px"},
        {text: "Actions", value: "actions", sortable: false},
      ],
    }
  },
  metaInfo() {
    return {
      title: `ລາຍລະອຽດແຜນເວລາຈັດສົ່ງ`,
    }
  },
  methods: {
    Back() {
      this.$router.push({name: 'RoutePlan'});
    },
    viewDetail(id) {
      let routeData = this.$router.resolve({name: 'CalendarDetailCustomer',params: {id: this.$route.params.id,detail_id:id}});
      window.open(routeData.href, '_blank');
    },
    getBgColorFunc(status) {
      return getBgColor(status)
    },
    statusLao(status) {
      return getLaoStatus(status)
    },
    fetchData() {
      this.TableLoading = true;
      this.$axios.get('branch/plan-calendar/' + this.$route.params.id,{
        params: queryOption([
          { page: this.pagination.current_page },
          { per_page: this.per_page },
          { status: this.filterStatus },
        ]),
      }).then(res => {
          this.routePlanDetail = res.data.customer.data;
          this.planCalendar = res.data;
          console.log(this.planCalendar);
          this.pagination = res.data.customer;
          this.TableLoading = false;
      }).catch(() => {
        this.TableLoading = false;
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    fetchDriver() {
      this.TableLoading = true;
      this.$admin.get('list-water-truck').then(res => {
        setTimeout(() => {
          this.trucks = res.data.trucks;
          this.TableLoading = false;
        }, 100);
      }).catch(() => {
        this.TableLoading = false;
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
  },
  watch: {
    filterStatus:function() {
      this.fetchData();
    }
  },
  created() {
    this.fetchData();
  }
};
</script>

<style>
</style>